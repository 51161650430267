@import '../../../styles/customMediaQueries.css';
.root {
  min-height: 100vh;
  display: block !important;
  position: relative;
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

.main {
  display: block;
}
.authenticateMain{
  @media (min-width: 1024px) {
    margin-left: 60px;
  }
}