@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  border: solid 1px var(--colorGrey100);
  background-color: var(--colorWhite);
  border-radius: var(--borderRadiusLarge);
  padding: 10px;
  transition: var(--transitionStyle);
  @media (--viewportSmall) {
    flex-direction: row;
    padding: 16px;
  }

  /* Remove link's hover effect */
  &:hover {
    transition: var(--transitionStyle);
    text-decoration: none;
    background-color: rgb(163 215 252 / 14%);
    border-color: rgb(163, 215, 252);
    box-shadow: rgb(163, 215, 252) 0px 0px 8px 0px;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--borderRadiusLarge);
  width: 170px;
  height: 120px;
  overflow: hidden;
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusLarge);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding-top: 10px;
  @media (--viewportSmall) {
    padding: 2px 0px 0 16px;
    width: calc(100% - 150px);
  }
  @media (--viewportLarge) {
    flex-direction: row;
  }
  & .infoLeft {
    width: 100%;
    display: flex;
    flex-direction: column;
    @media (--viewportLarge) {
      width: 70%;
    }
  }
  & .infoRight {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    @media (--viewportLarge) {
      margin-top: 0;
      width: 30%;
      text-align: right;
      flex-direction: column;
      align-items: flex-end;
    }
    & .listingImg {
      & > img {
        width: 60px;
        max-width: 100%;
        @media (--viewportLarge) {
          width: 100px;
        }
      }
    }
  }
}

.price {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-top: 5px;

  @media (--viewportSmall) {
    margin-top: 0px;
  }
}

.priceValue {
  composes: h5 from global;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey900);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  @media (--viewportLarge) {
    font-size: 18px;
  }
  @media (--viewportLargeWithPaddings) {
    font-size: 20px;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  composes: p from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--colorGrey900);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
}

.title {
  composes: h5 from global;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColor);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 0 6px 0;
  @media (--viewportSmall) {
    margin: 0 0 10px 0;
  }
  & .rating {
    margin-left: 8px;
    line-height: 0;
    & > span {
      line-height: 0;
    }
    & svg {
      width: 12px;
      height: 12px;
      margin: 0 1px;
      fill: var(--colorAttention);
    }
  }
}

.tournamentDetails {
  display: flex;
  flex-wrap: wrap;
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  margin: 0;
  & > span {
    color: var(--colorGrey900);
    font-size: 12px;
    margin: 1px 5px 0 4px;
    opacity: 0.5;
    text-decoration: line-through;
    @media (--viewportLargeWithPaddings) {
      font-size: 14px;
    }
    & > svg {
      width: 14px;
      height: 14px;
    }
    &.checked {
      opacity: 1;
      text-decoration: none;
    }
  }
}
.otherInfo {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  font-size: 12px;
  margin: 0 0 6px 0;
  @media (--viewportSmall) {
    margin: 0 0 10px 0;
  }
  @media (--viewportLargeWithPaddings) {
    font-size: 14px;
  }
  & > label {
    padding: 0;
    margin: 0;
    color: var(--colorGrey900);
    font-size: 12px;
    @media (--viewportLargeWithPaddings) {
      font-size: 14px;
      white-space: nowrap;
    }
  }
  & > span {
    color: var(--colorGrey900);
    margin: 0px 0 0 4px;
    font-size: 12px;
    @media (--viewportLargeWithPaddings) {
      font-size: 14px;
    }
  }
}
.description {
  composes: marketplaceTinyFontStyles from global;
  color: var(--colorGrey900);
  line-height: 150%;
  margin: 0;
}
.authorInfo {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey900);
  display: flex;
  font-size: 12px;
  align-items: center;
  margin: 0;
  @media (--viewportLargeWithPaddings) {
    font-size: 14px;
  }
  @media (--viewportMedium) {
    margin: 0 0 16px 0;
  }
  & > span {
    margin-left: 8px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.mapListingModalCard {
  & .aspectRatioWrapper {
    width: 110px;
    height: auto;
  }

  & .title {
    font-size: 16px;
    font-weight: var(--fontWeightSemiBold);
    margin: 0 0 8px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    & .rating {
      margin-top: 8px;
      margin-left: 0px;
      line-height: 0;
      & > span {
        line-height: 0;
      }
      & svg {
        width: 12px;
        height: 12px;
        margin: 0 1px;
        fill: var(--colorAttention);
      }
    }
  }
  & .info {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    padding: 8px 0px 0 8px;
    flex-direction: column;
    width: calc(100% - 110px);
    @media (--viewportLarge) {
      padding: 8px 0px 0 16px;
    }
    & .infoLeft {
      width: 100%;
    }

    & .otherInfo {
      font-size: 12px;
      margin: 0 0 6px 0;
      & > label {
        font-size: 12px;
      }
      & > span {
        margin: 1px 0 0 4px;
        font-size: 12px;
      }
    }
    & .tournamentDetails {
      margin: 0;
      & > span {
        font-size: 12px;
        & > svg {
          width: 12px;
          height: 12px;
        }
      }
    }
    & .listingImg {
      & > img {
        width: 100px;
        max-width: 100%;
      }
    }
    & .authorInfo {
      font-size: 12px;
      margin: 0 0 5px 0;
      & > a {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        & > span {
          font-size: 8px;
          line-height: 100%;
        }
      }
      & > span {
        margin-left: 6px;
      }
    }
    & .modalAuthorSec {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & .price {
        margin-top: 0px;

        & .priceValue {
          font-size: 15px;
        }
      }
    }
  }
}
